<template>
  <Details
    ref="details"
  >
    <loader :loading="loading" :backdrop="true" />
 
      <DetailsBar class="relative mb-10">
        <div class="flex justify-between">
          <div class="w-6/12">
            <div class="form-row form-narrow" :style="{ marginBottom: 0 }">
              <div class="form-col">
                <label class="label">unit</label>
                <div class="text-sm">101</div>
              </div>
              <div class="form-col">
                <label class="label">status</label>
                <div class="text-sm">active</div>
              </div>
              <div class="form-col">
                <label class="label">resident since</label>
                <div class="text-sm">12/14/2017</div>
              </div>
              <div class="form-col">
                <label class="label">lease exp</label>
                <div class="text-sm">12/13/2021</div>
              </div>
            </div>
          </div>
          <div class="w-6/12 border-l-2">
            <div class="px-2">
              <table class="table-auto w-full" cellspacing="0" cellpadding="0">
                <tr>
                  <th>residents</th>
                  <th>occupants</th>
                  <th>flags</th>
                </tr>
                <tr>
                  <td><router-link
                      class="assignment-link underline"
                      :to="{
                        name: 'manage_residents.index',
                        params: { isResident: true },
                      }"
                    >co-resident 1
                    </router-link>
                  </td>
                  <td></td>
                  <td>allow late fees</td>
                </tr>
                <tr>
                  <td><router-link
                      class="assignment-link underline"
                      :to="{
                        name: 'manage_residents.index',
                        params: { isResident: true },
                      }"
                    >co-resident 2
                    </router-link></td>
                  <td></td>
                  <td>allow online payments</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </DetailsBar>

      <div class="w-full">
        <div class="form-row mb-6 w-full">
          <div class="inline-block" v-bind:class="{ active: currentTab == 1 }">
            <label class="cursor-pointer" @click="selectTab(1)">unit ledger</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 2 }">
            <label class="cursor-pointer" @click="selectTab(2)">contact info</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 3 }">
            <label class="cursor-pointer" @click="selectTab(3)">general</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 4 }">
            <label class="cursor-pointer" @click="selectTab(4)">notes</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 5 }">
            <label class="cursor-pointer" @click="selectTab(5)">history</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 6 }">
            <label class="cursor-pointer" @click="selectTab(6)">resident portal</label>
          </div>
          <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 7 }">
            <label class="cursor-pointer" @click="selectTab(7)">IoT</label>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1 overflow-auto">
        <div v-if="this.currentTab == 2">
          <p class="font-bold text-lg text-gray-500 mt-6">personal details</p>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">first name</label>
                <p class="text-gray-800" v-text="this.fees.first_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">last name</label>
                <p class="text-gray-800" v-text="this.fees.last_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">status</label>
                <div v-if="this.fees.status == 1">
                  <p class="text-gray-800">active</p>
                </div>
                <div v-else>
                  <p class="text-gray-800">inactive</p>
                </div>  
              </div>
              <div class="form-col"></div>              
            </div>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">primary phone</label>
                <p class="text-gray-800" v-text="this.fees.primaryphone"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">work phone</label>
                <p class="text-gray-800" v-text="this.fees.workphone"></p>
              </div>        
              <div class="form-col">
                <label class="font-bold text-lg">email address</label>
                <p class="text-gray-800" v-text="this.fees.email"></p>
              </div> 
              <div class="form-col">
                <label class="font-bold text-lg">contact preference</label>
                <p class="text-gray-800" v-text="this.fees.contact_preferences"></p>
              </div>                                                      
            </div> 
          <p class="font-bold text-lg text-gray-500 mt-6">emergency contact</p>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">first name</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.first_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">last name</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.last_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">relationship</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.relationship"></p> 
              </div>
              <div class="form-col"></div>              
            </div>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">primary phone</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.primaryphone"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">work phone</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.workphone"></p>
              </div>        
              <div class="form-col">
                <label class="font-bold text-lg">email address</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.email"></p>
              </div> 
              <div class="form-col"></div>                                                      
            </div>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">address</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.address"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">city</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.city"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">state</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.state"></p> 
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">zip</label>
                <p class="text-gray-800" v-text="this.fees.emergency_contact.zip"></p>                 
              </div>              
            </div>                        
        </div>
        <div v-if="this.currentTab == 3">
          <p class="font-bold text-lg text-gray-500 mt-6">associated occupants</p>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">first name</label>
                <p class="text-gray-800" v-text="this.fees.first_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">last name</label>
                <p class="text-gray-800" v-text="this.fees.last_name"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">date of birth</label>
                <p class="text-gray-800" v-text="this.fees.date_of_birth"></p>
              </div>
              <div class="form-col"></div>              
            </div>
          <p class="font-bold text-lg text-gray-500 mt-6">pets</p>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">category</label>
                <p class="text-gray-800" v-text="this.fees.pets[0].category"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">type</label>
                <p class="text-gray-800" v-text="this.fees.pets[0].type"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">name</label>
                <p class="text-gray-800" v-text="this.fees.pets[0].name"></p> 
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">designed registered?</label>
                <div v-if="this.fees.pets[0].designed_registered == 1">
                  <p class="text-gray-800">Si</p>
                </div>
                <div v-else>
                  <p class="text-gray-800">No</p>
                </div>                
              </div>              
            </div>
            <p class="font-bold text-lg text-gray-500 mt-6">vehicles</p>
            <div class="form-row mt-6">
              <div class="form-col">
                <label class="font-bold text-lg">make</label>
                <p class="text-gray-800" v-text="this.fees.vehicles[0].make"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">model</label>
                <p class="text-gray-800" v-text="this.fees.vehicles[0].model"></p>
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">color</label>
                <p class="text-gray-800" v-text="this.fees.vehicles[0].color"></p> 
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">year</label>
                <p class="text-gray-800" v-text="this.fees.vehicles[0].year"></p>                 
              </div>
              <div class="form-col">
                <label class="font-bold text-lg">license plate</label>
                <p class="text-gray-800" v-text="this.fees.vehicles[0].license_plate"></p>                 
              </div>                             
            </div>                        
        </div> 
        <div v-if="this.currentTab == 4">
          <p v-if="!addNew" @click="addNew = true" class="text-blue-500 font-frank font-500 underline block cursor-pointer flex mt-4" style="flex-direction: row-reverse;">
            add new
            <Icon name="plus" class="2-3 h-5 mr-1"/>            
          </p> 
          <DetailsBar class="mt-4">
            <p class="font-bold">{{ this.fees.notes[0].date }} - {{ this.fees.first_name }}</p>
            <label class="" v-text="this.fees.notes[0].text"></label>
          </DetailsBar>    
        </div>     
      </div>   
  </Details>

</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import Loader from '@/components/ui/Loader';
import Details from '@/components/auth/details/Details';
import DetailsBar from '@/components/auth/details/DetailsBar';
import Icon from "@/components/ui/Icon";

export default {
  name: 'ResidentDetails',
  mixins: [
    AuthMixin,
    ModalNavigation
  ], 
  components: {
    Loader,
    Details,
    DetailsBar,
    Icon
  },
  data(){
    return{
      loading: false,
      currentTab: 1,
      fees:[],
    }
  },
  methods:{
      fetchResponses() {
        const data = [
          {
            "id": 1,
            "entity_id": 5001,
            "first_name": "Wendell",
            "last_name": "Guillen Brenes",
            "primaryphone": "77777777",
            "workphone": "88888888",
            "status": 1,
            "date_of_birth": "26/04/2001",
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z",
            "email": "wendell.guillen@oktara.com",
            "contact_preferences": "email",
            "emergency_contact": 
              {
              "id": 1,
              "first_name": "Alejandro",
              "last_name": "Gonzales",
              "primaryphone": "55555555",
              "workphone": "66666666",
              "relationship": "best friend",
              "email": "alejandro.gonzales@oktara.com",
              "address": "17261 S.YesterYear Dr.",
              "city": "Phoenix",
              "state": "AZ",
              "zip": "38929",
              "created_date": "2021-10-05T20:12:31.849Z",
              "updated_date": "2021-10-05T20:12:31.849Z"
              }
            ,  
            "pets": [
              {
                "id": 1,
                "name": "Bruno",
                "type": "Boxer",
                "category": "dog",
                "designed_registered": 0,                
              }
            ],
            "vehicles": [
              {
                "id": 1,
                "make": "subaru",
                "model": "outback",
                "color": "blanck",
                "year": "2020",
                "license_plate": "TC HKL 89J"                  
              }
            ],
            "notes":[
              {
                "id": 1,
                "date": "05/12/2022",
                "text": "The best teamwork is achieved by people who work independently with a common goal, James Cash Penney.",
              }
            ]
          }
        ]
          this.fees = data[0];

        },    
     selectTab(nextTab) {
      if (this.currentTab !== nextTab) {
        this.currentTab = nextTab;
      }
    },
  },
  mounted() {
    this.fetchResponses()
  }
}
</script>

<style scoped>
  tr {
    --bg-opacity: 0;
    background-color: transparent;
    background-color: transparent;
  }

  th {
      padding-left: 0rem;
      padding-right: 0rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
      text-transform: lowercase;
      position: sticky;
      top: 0;
      --bg-opacity: 0;
      background-color: transparent;
      background-color: transparent;
      z-index: 10;
      border-bottom: 1px solid black;
  }

  td {
      padding-left: 0rem;
      padding-right: 0rem;
      padding-top: 0.5rem;
      padding-bottom: 0rem;
  }
  .active {
    color: var(--highlightColor500);
    border-bottom: 1px solid;
    border-color: var(--highlightColor500) !important;
   
  }

</style>